<template>
  <div>
    <v-alert
      :value="showAlert"
      colored-border
      dismissible
      color="primary lighten-1"
      border="top"
      icon="mdi-check"
      transition="scale-transition"
      elevation="2"
      prominent
    >
      🤾🏻‍♀️ 🤸🏻‍♂️ 🏌🏻‍♀️ 🤾🏻‍♂️ Your export is currently processing. Once the export is completed 🏁, you will receive a notification via Slack under
      <code>#admindashboard</code> channel 📢.
    </v-alert>
    <v-card>
      <v-card-title>Audience Lookup</v-card-title>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="genders"
              label="Gender"
              multiple
              item-text="id"
              item-value="id"
              chips
              deletable-chips
              persistent-hint
              v-model="searchCriteria.gender"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="maratialStatus"
              label="Marital Status"
              multiple
              item-text="id"
              item-value="id"
              chips
              deletable-chips
              persistent-hint
              v-model="searchCriteria.mStatus"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="ageRanges"
              label="Age Range"
              multiple
              item-text="id"
              item-value="id"
              chip
              deletable-chips
              persistent-hint
              v-model="searchCriteria.ageGroup"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="countries"
              label="Country"
              multiple
              item-text="_id"
              item-value="_id"
              chips
              deletable-chips
              persistent-hint
              v-model="searchCriteria.country"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="appsInstalled"
              label="Apps Installed"
              multiple
              item-text="_id"
              item-value="_id"
              chips
              deletable-chips
              persistent-hint
              v-model="searchCriteria.appInstalled"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="deviceOs"
              label="Operating System"
              multiple
              item-text="_id"
              item-value="_id"
              chips
              deletable-chips
              persistent-hint
              v-model="searchCriteria.deviceInfo.os"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="deviceBrand"
              label="Device brand"
              multiple
              item-text="_id"
              item-value="_id"
              chips
              deletable-chips
              persistent-hint
              v-model="searchCriteria.deviceInfo.brand"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete
              :items="projectCode"
              label="Project Code"
              multiple
              item-text="name"
              item-value="code"
              chips
              deletable-chips
              persistent-hint
              v-model="searchCriteria.projectReferralCode"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <v-menu
              ref="startDateMenu"
              v-model="startDateMenu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="searchCriteria.startDate"
                  label="Joined After"
                  prepend-icon="event"
  
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker color="primary" no-title scrollable v-model="searchCriteria.startDate">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$refs.startDateMenu.save(Date(startDate))">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="6">
            <v-menu     
              ref="endDateMenu"
              v-model="endDateMenu"
              :close-on-content-click="false"
              :return-value.sync="endDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="searchCriteria.endDate"
                  label="Joined Before"
                  prepend-icon="event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker no-title color="primary" scrollable v-model="searchCriteria.endDate">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$refs.endDateMenu.save(Date(endDate))">OK</v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <div v-for="(criteria, j) in searchCriteria.customCriteria" :key="criteria._id">
          <v-row>
            <v-col cols="12" sm="4">
              <v-autocomplete
                :items="distinctQuestions"
                label="Distinct Questions"
                item-text="title"
                item-value="title"
                chips
                return-object
                deletable-chips
                persistent-hint
                @change="selected => populateAnswers(selected, searchCriteria.customCriteria[j])"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="2">
                <v-autocomplete
                :items="operator"
                label="is"
                item-text="name"
                item-value="_id"
                chips
                return-object
                deletable-chips
                persistent-hint
                ></v-autocomplete>

            </v-col>
            <v-col cols="12" sm="4">
              <v-autocomplete
                :items="getAnswerArray(searchCriteria.customCriteria[j].title)"
                label="Answer"
                chips
                deletable-chips
                persistent-hint
                v-model="searchCriteria.customCriteria[j].answer"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-icon @click="removeRow(criteria, j )" class="mt-3" color="primary">cancel</v-icon>
            </v-col>
          </v-row>
        </div>
        <v-btn color="success" @click="addCustomFilter" class="mr-4">Add Custom Filter</v-btn>
        <v-btn @click="exportUsers()" color="primary" class="mr-4">Search and Export</v-btn>

      </v-container>
    </v-card>
  </div>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";

const service = new RestResource();
export default {
  data() {
    return {
      searchCriteria: {
        deviceInfo: {},
        customCriteria: []
      },
      customCriterion: [],
      projectCode: [],
      users: [],
      distinctQuestions: [],
      genders: [{ id: "Male" }, { id: "Female" }],
      maratialStatus: [{ id: "Single" }, { id: "Married" }],
      operator: [
        { _id: "==", name: "Equal to" }
      ],
      ageRanges: [
        { id: "18 - 25" },
        { id: "26 - 35" },
        { id: "36 - 45" },
        { id: "46 - 55" }
      ],
      countries: [],
      appsInstalled: [],
      deviceBrand: [],
      deviceOs: [],
      nextPage: 1,
      totalDocs: 0,
      startDate: null,
      endDate: null,
      startDateMenu: false,
      endDateMenu: false,
      showAlert: false,
      answerArray: [],
    };
  },

  mounted() {
    this.getDistinctQuestions();
    this.fetchApps();
    this.fetchCountries();
    this.fetchDeviceBrand();
    this.fetchDeviceOs();
    this.fetchProjectCode();
  },

  methods: {
    addCustomFilter() {
      this.searchCriteria.customCriteria.push({});
    },
    exportUsers() {
      service.filterUsers(this.searchCriteria)
      this.showAlert = true
    },
    getDistinctQuestions() {
      service.getDistinctQuestions().then(r => {
        this.distinctQuestions = r.data;
      });
    },

    fetchApps() {
      service.getAppList().then(r => {
        this.appsInstalled = r.data;
      });
    },

    fetchCountries() {
      service.getCountryList().then(r => {
        this.countries = r.data;
      });
    },

    fetchDeviceBrand() {
      service.getDeviceBrand().then(r => {
        this.deviceBrand = r.data;
      });
    },

    fetchDeviceOs() {
      service.getDeviceOs().then(r => {
        this.deviceOs = r.data;
      });
    },

    fetchProjectCode() {
      service.fetchProjectCodes().then(r => {
        this.projectCode = r.data;
      });
    },
    getCurrentTimeStamp() {
      var currentdate = new Date();
      var datetime =
        currentdate.getDate() +
        "/" +
        (currentdate.getMonth() + 1) +
        "/" +
        currentdate.getFullYear() +
        " @ " +
        currentdate.getHours() +
        ":" +
        currentdate.getMinutes();

      return datetime;
    },

    removeRow(j, rowIdx) {
      this.searchCriteria.customCriteria.splice(rowIdx, 1);
    },

    populateAnswers(selectedQuestion, searchObj) {
      searchObj.title = selectedQuestion.title

      let found = this.answerArray.filter(row => row.title == selectedQuestion.title)

      if (found.length == 0) {
        this.answerArray.push({
          title: selectedQuestion.title,
          answer: selectedQuestion.answers ? selectedQuestion.answers : []
        })
      } else {
        found[0] = {
          title: selectedQuestion.title,
          answer: selectedQuestion.answers ? selectedQuestion.answers : []
        }
      }
    },

    getAnswerArray(questionTitle) {
      let found = this.answerArray.filter(row => row.title == questionTitle) 
      return found.length == 1 ? found[0].answer : []
    }
  }
};
</script>